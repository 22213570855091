import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { LOCALES } from '../i18n';

export const defaultState = {
  language: LOCALES.ES,
};

/* eslint-disable */
const AppContext = React.createContext([{}, () => {}]);
// eslint-disable-next-line react/function-component-definition
const AppContextProvider = ({ children }) => {
  const [state, setState] = useState({ ...defaultState });

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};
AppContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { AppContext, AppContextProvider };
