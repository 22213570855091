// src/utils/gatsby-gdpr.js

export const initializeGA = (trackingId) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", trackingId, { anonymize_ip: true });

    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);
  }
};

export const initializeGTM = (trackingId) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}`;
    script.async = true;
    document.head.appendChild(script);
  }
};
