import React from 'react';
import { PropTypes } from 'prop-types';
import './style.scss';

const Button = ({
  children,
  style = '',
  onClick = () => {},
  isDisabled = false,
  type = 'button',
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`btn ${isDisabled ? ' disabled' : ''} ${style}`}
    >
      {children}
    </button>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.PropTypes.object,
  onClick: PropTypes.PropTypes.func,
  style: PropTypes.PropTypes.string,
  isDisabled: PropTypes.PropTypes.bool,
};
