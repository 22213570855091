import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { Spinner } from '@components';

import { useContact } from '../../hooks';

import * as styles from './SocialMedia.module.scss';

const SocialMedia = ({ intl: { formatMessage } }) => {
  const [email, setEmail] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const { sendContact, isError, isSuccess, isLoading } = useContact({
    handleReset: () => {
      setEmail('');
    },
  });
  const Regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const handleValidateEmail = (text = '') => Regex.test(text);

  const handleSend = async (e) => {
    e.preventDefault();
    const isValidEmail = handleValidateEmail(email);
    setIsEmailError(!isValidEmail);
    if (isValidEmail) {
      await sendContact({
        email,
        name: '',
        code: '',
        phone: '',
      });
    }
  };

  const handleChangeEmail = (text = '') => {
    setEmail(text);
    if (isEmailError) {
      setIsEmailError(!handleValidateEmail(text));
    }
  };

  const socials = [
    {
      id: 0,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/x.webp"
            alt="x"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      ),
      link: 'https://x.com/connectingtechp',
    },
    {
      id: 3,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/facebook.webp"
            alt="facebook"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      ),
      link: 'https://www.facebook.com/people/Connecting-Tech-People/61561453243630/',
    },
    {
      id: 4,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/instagram.webp"
            alt="instagram"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      ),
      link: 'https://www.instagram.com/connectingtechpeople',
    },
    {
      id: 1,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/linkedin.webp"
            alt="linkedin"
            placeholder="blurred"
          />
        </div>
      ),
      link: 'https://www.linkedin.com/company/connecting-tech-people-sl/',
    },
    {
      id: 10,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/clutch.webp"
            alt="clutch"
            placeholder="blurred"
          />
        </div>
      ),
      link: 'https://clutch.co/profile/connecting-tech-people',
    },
    {
      id: 8,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/goodfirms.webp"
            alt="goodfirms"
            placeholder="blurred"
          />
        </div>
      ),
      link: 'https://www.goodfirms.co/company/connecting-tech-people',
    },
    {
      id: 5,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/g2.webp"
            alt="g2"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      ),
      link: 'https://www.g2.com/',
    },
    {
      id: 6,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src={'../../images/socialmedia/techbehemoths.webp'}
            alt="techbehemoths"
            className={styles.social_media__icon}
            objectFit="cover"
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
          />
        </div>
      ),
      link: 'https://techbehemoths.com/',
    },
    {
      id: 7,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/hubspot.webp"
            alt="hubspot"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      ),
      link: 'https://hubspot.com',
    },
    {
      id: 9,
      icon: (
        <div className={styles.social_media__content_icon}>
          <StaticImage
            src="../../images/socialmedia/apollo_io.webp"
            alt="apollo"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      ),
      link: 'https://Apolo.io',
    },
  ];

  const handleStyleStatusEmail = () => {
    if (isError || isEmailError) {
      return styles.text_error;
    }
    if (isSuccess) {
      return styles.text_success;
    }
    return '';
  };

  return (
    <div className={styles.social_media}>
      <div className={styles.social_media__content}>
        <h4>{formatMessage({ id: 'getInTouch' })}</h4>
        <div className={styles.social_media__content_input}>
          <input
            className={`${styles.social_media__content_input__input} ${handleStyleStatusEmail()}`}
            name="email"
            disabled={isError || isSuccess}
            value={
              isError
                ? formatMessage({ id: 'tryAgain' })
                : isSuccess
                  ? formatMessage({ id: 'sent' })
                  : email
            }
            onChange={(v) => {
              handleChangeEmail(v.target.value);
            }}
            placeholder={formatMessage({ id: 'email' })}
          />
          <button
            onClick={handleSend}
            className={styles.social_media__content_input__content_send}
          >
            {isLoading ? (
              <Spinner isLoading={isLoading} />
            ) : (
              <StaticImage
                className={styles.social_media__content_input__img}
                src="../../images/socialmedia/send.webp"
                alt="send"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
              />
            )}
          </button>
        </div>
      </div>
      <div className={styles.social_media__container_icon}>
        {socials.map((social) => (
          <a href={social.link} target="_blank" key={social.id}>
            {social.icon}
          </a>
        ))}
      </div>
    </div>
  );
};
export default injectIntl(SocialMedia);

SocialMedia.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
