// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var social_media = "SocialMedia-module__social_media___0lr7c";
export var social_media__container_icon = "SocialMedia-module__social_media__container_icon___gwhVs";
export var social_media__content = "SocialMedia-module__social_media__content___XboYy";
export var social_media__content_icon = "SocialMedia-module__social_media__content_icon___da6RM";
export var social_media__content_input = "SocialMedia-module__social_media__content_input___gHiKR";
export var social_media__content_input__content_send = "SocialMedia-module__social_media__content_input__content_send___svWss";
export var social_media__content_input__img = "SocialMedia-module__social_media__content_input__img___yO5RJ";
export var social_media__content_input__input = "SocialMedia-module__social_media__content_input__input___3t7tR";
export var social_media__icon = "SocialMedia-module__social_media__icon___J2xDl";
export var spacing = "8";
export var text_error = "SocialMedia-module__text_error___XTZa8";
export var text_success = "SocialMedia-module__text_success___joEKL";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";