// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var container_contact = "Contact-module__container_contact___yCBXh";
export var container_contact__container_captcha = "Contact-module__container_contact__container_captcha___X-CEg";
export var container_contact__container_form = "Contact-module__container_contact__container_form___dFQUl";
export var container_contact__container_phone = "Contact-module__container_contact__container_phone___EycBb";
export var container_contact__container_phone__select_phone = "Contact-module__container_contact__container_phone__select_phone___jhVSF";
export var container_contact__content_btn = "Contact-module__container_contact__content_btn___u5aL5";
export var container_contact__content_form = "Contact-module__container_contact__content_form___o5pf7";
export var container_contact__content_images = "Contact-module__container_contact__content_images___W6zex";
export var container_contact__content_input = "Contact-module__container_contact__content_input___SGLum";
export var container_contact__content_input__img = "Contact-module__container_contact__content_input__img___ct2bZ";
export var container_contact__content_terms = "Contact-module__container_contact__content_terms___K5kiJ";
export var container_contact__content_titles = "Contact-module__container_contact__content_titles___MERLu";
export var container_contact__highlight = "Contact-module__container_contact__highlight___hF02Q";
export var container_contact__input = "Contact-module__container_contact__input___8HmiS";
export var container_contact__loading = "Contact-module__container_contact__loading___tzWGf";
export var container_contact__text_error = "Contact-module__container_contact__text_error___N8WOl";
export var container_contact__text_privacy = "Contact-module__container_contact__text_privacy___y6H0z";
export var container_contact__text_success = "Contact-module__container_contact__text_success___Ls3wS";
export var container_contact__text_term = "Contact-module__container_contact__text_term___7WUOa";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";