import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './locales';
import messages from './messages';

const Provider = ({ children, locale = LOCALES.ES }) => (
  <IntlProvider
    locale={locale.value}
    textComponent={Fragment}
    messages={messages[locale.value]}
  >
    {children}
  </IntlProvider>
);

Provider.propTypes = {
  children: PropTypes.any.isRequired,
  locale: PropTypes.object,
};

export default Provider;
