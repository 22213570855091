import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

/* eslint-disable */
export const LOCALES = {
  EN: {
    value: 'en-US',
    image: <StaticImage src="../images/usa.webp" alt="eng_flag" width={37} placeholder="blurred" />,
  },
  ES: {
    value: 'es-ES',
    image: <StaticImage src="../images/spain.webp" alt="eng_flag" width={37} placeholder="blurred" />,
  },
};
