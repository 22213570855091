import React from 'react';
import { Stack, Typography, Paper } from '@mui/material';

import * as styles from './card.module.scss';
import PropTypes from 'prop-types';

const Card = ({ image, title, content, subtitle }) => {
  return (
    <Paper elevation={0} className={styles.card}>
      <Stack
        direction={{ xs: 'row', lg: 'column' }}
        alignItems="start"
        spacing={2}
      >
        {image}
        <Stack>
          <Typography variant="h5" className={styles.card__title}>
            {title}
          </Typography>
          {content && !subtitle && (
            <Typography variant="subtitle1" className={styles.card__content}>
              {content}
            </Typography>
          )}
          {content && subtitle && (
            <>
              <Typography variant="subtitle1" className={styles.card__content}>
                {subtitle} {' '}
                {content}
              </Typography>

            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

Card.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
};

export default Card;
