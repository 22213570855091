// extracted by mini-css-extract-plugin
export var body_background = "#f8f9fa";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var footer = "Footer-module__footer___iTLBh";
export var footer__footer_container = "Footer-module__footer__footer_container___Eo2-L";
export var footer__footer_container__content_text = "Footer-module__footer__footer_container__content_text___6fp8T";
export var footer__footer_container__content_text__title = "Footer-module__footer__footer_container__content_text__title___8Ih-h";
export var footer__footer_container__img = "Footer-module__footer__footer_container__img___WR1bm";
export var footer__footer_container__line = "Footer-module__footer__footer_container__line___r8YOh";
export var lg = "1200px";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "#2ed3ae";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var white = "#fff";
export var xl = "1536px";
export var xxl = "2500px";