import * as React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { AppContext } from '../../context/AppContext';
import { LOCALES } from '../../i18n';
import './style.scss';

const Navbar = ({ intl: { formatMessage } }) => {
  const [appContext, setAppContext] = React.useContext(AppContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState('/');

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleLocationChange = () => {
        setCurrentPage(window.location.pathname);
      };
      handleLocationChange();
      window.addEventListener('popstate', handleLocationChange);

      return () => {
        window.removeEventListener('popstate', handleLocationChange);
      };
    }
  }, [typeof window !== 'undefined' ? window.location.pathname : null]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const changeLanguage = (locale) => () => {
    setAppContext((currState) => ({
      ...currState,
      language: locale,
    }));
    handleCloseUserMenu();
  };
  const pages = [
    {
      id: 0,
      page: '/',
      text: formatMessage({ id: 'home' }),
    },
    {
      id: 1,
      page: '/aboutUs/',
      text: formatMessage({ id: 'aboutUs' }),
    },
    {
      id: 2,
      page: '/business/',
      text: formatMessage({ id: 'businessTalent' }),
    },
    {
      id: 3,
      page: '/professionals/',
      text: formatMessage({ id: 'professionalsJob' }),
    },
    {
      id: 4,
      page: '/contact/',
      text: formatMessage({ id: 'contactUs' }),
    },
  ];

  const isActive = (link) => currentPage === link;

  return (
    <Box sx={{ flexGrow: 1 }} className="header_section">
      <AppBar position="static" className="app-bar__header">
        <Toolbar className="header">
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
            }}
          >
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.page}
                    className={isActive(page.page) ? 'active' : 'inactive'}
                  >
                    <Typography
                      textAlign="center"
                      className={isActive(page.page) ? 'active' : 'inactive'}
                    >
                      {page.text}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
            <Link to="/">
              <StaticImage
                src="../../images/isotipo.webp"
                className="_app_bar_logo_header_mobile"
                alt="logo"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
                formats={['auto', 'webp', 'avif']}
              />

            </Link>
            <span className="logo_text_mobile">Connecting Tech People</span>
          </Box>
          <Link to="/">
            <StaticImage
              src="../../images/isotipo.webp"
              className="_app_bar_logo_header"
              alt="logo"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
            />

          </Link>
          <span className="logo_text">Connecting Tech People</span>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {pages.map((page) => (
              <ul key={page.id}>
                <li>
                  <Link
                    to={page.page}
                    onClick={handleCloseNavMenu}
                    className={isActive(page.page) ? 'active' : 'inactive'}
                  >
                    {page.text}
                  </Link>
                </li>
              </ul>
            ))}
          </Box>
          <Button
            className={'content_flag'}
            onClick={handleOpenUserMenu}
            variant="text"
            color="success"
          >
            {appContext.language.image}
            <StaticImage
              src="../../images/arrow_down.webp"
              alt="arrow"
              placeholder="blurred"
              loading="lazy"
              layout="constrained"
              formats={['auto', 'webp', 'avif']}
              width={14}
              height={8}
            />
          </Button>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {Object.entries(LOCALES).map((l) => (
              <MenuItem
                key={l[0]}
                onClick={changeLanguage(l[1])}
                selected={l[1].value === appContext.language}
              >
                {l[1].image}
                <Typography textAlign="center" ml={1}>
                  {l[0]}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default injectIntl(Navbar);

Navbar.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
