import './src/styles/css/style.scss';

import React from 'react';
import MainContainer from './src/mainContainer';
import CookieConsent from './src/components/cookieConsent';

export const wrapRootElement = ({ element }) => {
  return (
    <MainContainer>
      <CookieConsent />
      {element}
    </MainContainer>
  );
};
