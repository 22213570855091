// extracted by mini-css-extract-plugin
export var background_images = "card-module__background_images___7UV7K";
export var body_background = "#f8f9fa";
export var card = "card-module__card___TTLUX";
export var card__content = "card-module__card__content___Orc-4";
export var card__subtitle = "card-module__card__subtitle___2WB--";
export var card__title = "card-module__card__title___+yT1n";
export var chip = "card-module__chip___7y+1+";
export var container = "card-module__container___DmkrU";
export var content = "card-module__content___B3sER";
export var even_second_color = "#557da1";
export var font_family = "-apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,\"Noto Sans\",sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"";
export var full_img = "card-module__full_img___1x9H5";
export var full_imgSmall = "card-module__full_img--small___uTAFG";
export var gray_21 = "card-module__gray_21___vm9yc";
export var image_container = "card-module__image_container___s0hlu";
export var image_item = "card-module__image_item___yfWF+";
export var lg = "1200px";
export var logo = "card-module__logo___AImPg";
export var md = "900px";
export var paper_background = "#fff";
export var primary = "card-module__primary___Ii65o";
export var primary_light = "#e0f9f3";
export var secondary = "#868d96";
export var sm = "600px";
export var spacing = "8";
export var title = "card-module__title___bFBem";
export var white = "#fff";
export var white_button = "card-module__white_button___Op18I";
export var xl = "1536px";
export var xxl = "2500px";