exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-banner-jsx": () => import("./../../../src/pages/aboutUs/components/banner.jsx" /* webpackChunkName: "component---src-pages-about-us-components-banner-jsx" */),
  "component---src-pages-about-us-components-culture-jsx": () => import("./../../../src/pages/aboutUs/components/culture.jsx" /* webpackChunkName: "component---src-pages-about-us-components-culture-jsx" */),
  "component---src-pages-about-us-components-history-jsx": () => import("./../../../src/pages/aboutUs/components/history.jsx" /* webpackChunkName: "component---src-pages-about-us-components-history-jsx" */),
  "component---src-pages-about-us-components-professionals-gallery-jsx": () => import("./../../../src/pages/aboutUs/components/professionalsGallery.jsx" /* webpackChunkName: "component---src-pages-about-us-components-professionals-gallery-jsx" */),
  "component---src-pages-about-us-components-professionals-jsx": () => import("./../../../src/pages/aboutUs/components/professionals.jsx" /* webpackChunkName: "component---src-pages-about-us-components-professionals-jsx" */),
  "component---src-pages-about-us-components-reviews-jsx": () => import("./../../../src/pages/aboutUs/components/reviews.jsx" /* webpackChunkName: "component---src-pages-about-us-components-reviews-jsx" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/aboutUs/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-business-components-banner-jsx": () => import("./../../../src/pages/business/components/banner.jsx" /* webpackChunkName: "component---src-pages-business-components-banner-jsx" */),
  "component---src-pages-business-components-brands-gallery-jsx": () => import("./../../../src/pages/business/components/brandsGallery.jsx" /* webpackChunkName: "component---src-pages-business-components-brands-gallery-jsx" */),
  "component---src-pages-business-components-brands-jsx": () => import("./../../../src/pages/business/components/brands.jsx" /* webpackChunkName: "component---src-pages-business-components-brands-jsx" */),
  "component---src-pages-business-components-for-you-dotted-frame-jsx": () => import("./../../../src/pages/business/components/forYou/dottedFrame.jsx" /* webpackChunkName: "component---src-pages-business-components-for-you-dotted-frame-jsx" */),
  "component---src-pages-business-components-for-you-recommendation-card-jsx": () => import("./../../../src/pages/business/components/forYou/recommendationCard.jsx" /* webpackChunkName: "component---src-pages-business-components-for-you-recommendation-card-jsx" */),
  "component---src-pages-business-components-for-you-recommended-section-jsx": () => import("./../../../src/pages/business/components/forYou/recommendedSection.jsx" /* webpackChunkName: "component---src-pages-business-components-for-you-recommended-section-jsx" */),
  "component---src-pages-business-components-who-work-index-jsx": () => import("./../../../src/pages/business/components/whoWork/index.jsx" /* webpackChunkName: "component---src-pages-business-components-who-work-index-jsx" */),
  "component---src-pages-business-components-who-work-who-work-desktop-jsx": () => import("./../../../src/pages/business/components/whoWork/whoWorkDesktop.jsx" /* webpackChunkName: "component---src-pages-business-components-who-work-who-work-desktop-jsx" */),
  "component---src-pages-business-components-who-work-who-work-mobile-jsx": () => import("./../../../src/pages/business/components/whoWork/whoWorkMobile.jsx" /* webpackChunkName: "component---src-pages-business-components-who-work-who-work-mobile-jsx" */),
  "component---src-pages-business-components-why-work-jsx": () => import("./../../../src/pages/business/components/whyWork.jsx" /* webpackChunkName: "component---src-pages-business-components-why-work-jsx" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-contact-components-banner-jsx": () => import("./../../../src/pages/contact/components/banner.jsx" /* webpackChunkName: "component---src-pages-contact-components-banner-jsx" */),
  "component---src-pages-contact-components-contact-info-jsx": () => import("./../../../src/pages/contact/components/contactInfo.jsx" /* webpackChunkName: "component---src-pages-contact-components-contact-info-jsx" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookies-index-js": () => import("./../../../src/pages/cookies/index.js" /* webpackChunkName: "component---src-pages-cookies-index-js" */),
  "component---src-pages-home-components-about-components-about-details-index-js": () => import("./../../../src/pages/home/components/about/components/aboutDetails/index.js" /* webpackChunkName: "component---src-pages-home-components-about-components-about-details-index-js" */),
  "component---src-pages-home-components-about-index-js": () => import("./../../../src/pages/home/components/about/index.js" /* webpackChunkName: "component---src-pages-home-components-about-index-js" */),
  "component---src-pages-home-components-areas-components-areas-cards-index-js": () => import("./../../../src/pages/home/components/areas/components/areasCards/index.js" /* webpackChunkName: "component---src-pages-home-components-areas-components-areas-cards-index-js" */),
  "component---src-pages-home-components-areas-components-areas-info-index-js": () => import("./../../../src/pages/home/components/areas/components/areasInfo/index.js" /* webpackChunkName: "component---src-pages-home-components-areas-components-areas-info-index-js" */),
  "component---src-pages-home-components-areas-index-js": () => import("./../../../src/pages/home/components/areas/index.js" /* webpackChunkName: "component---src-pages-home-components-areas-index-js" */),
  "component---src-pages-home-components-main-banner-components-statistics-index-js": () => import("./../../../src/pages/home/components/mainBanner/components/statistics/index.js" /* webpackChunkName: "component---src-pages-home-components-main-banner-components-statistics-index-js" */),
  "component---src-pages-home-components-main-banner-index-js": () => import("./../../../src/pages/home/components/mainBanner/index.js" /* webpackChunkName: "component---src-pages-home-components-main-banner-index-js" */),
  "component---src-pages-home-components-you-tel-us-what-you-need-components-features-index-js": () => import("./../../../src/pages/home/components/youTelUsWhatYouNeed/components/features/index.js" /* webpackChunkName: "component---src-pages-home-components-you-tel-us-what-you-need-components-features-index-js" */),
  "component---src-pages-home-components-you-tel-us-what-you-need-components-support-index-js": () => import("./../../../src/pages/home/components/youTelUsWhatYouNeed/components/support/index.js" /* webpackChunkName: "component---src-pages-home-components-you-tel-us-what-you-need-components-support-index-js" */),
  "component---src-pages-home-components-you-tel-us-what-you-need-index-js": () => import("./../../../src/pages/home/components/youTelUsWhatYouNeed/index.js" /* webpackChunkName: "component---src-pages-home-components-you-tel-us-what-you-need-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-sidebar-index-js": () => import("./../../../src/pages/policy-sidebar/index.js" /* webpackChunkName: "component---src-pages-policy-sidebar-index-js" */),
  "component---src-pages-private-policy-index-js": () => import("./../../../src/pages/private-policy/index.js" /* webpackChunkName: "component---src-pages-private-policy-index-js" */),
  "component---src-pages-professionals-components-banner-jsx": () => import("./../../../src/pages/professionals/components/banner.jsx" /* webpackChunkName: "component---src-pages-professionals-components-banner-jsx" */),
  "component---src-pages-professionals-components-connect-jsx": () => import("./../../../src/pages/professionals/components/connect.jsx" /* webpackChunkName: "component---src-pages-professionals-components-connect-jsx" */),
  "component---src-pages-professionals-index-js": () => import("./../../../src/pages/professionals/index.js" /* webpackChunkName: "component---src-pages-professionals-index-js" */)
}

