import { useState } from 'react';
import { sendContactService } from '../services/contact';

const useContact = ({ handleReset = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);
  const sendContact = async (user) => {
    try {
      setIsLoading(true);
      await sendContactService({
        email: user.email,
        name: user.name,
        tlf: `${user.code}${user.phone}`,
        message: user.message,
      });
      setIsSuccess(true);
      handleReset();
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setIsError(null);
        setIsSuccess(null);
      }, 4000);
    }
  };

  return {
    sendContact,
    isError,
    isSuccess,
    isLoading,
  };
};

export default useContact;
