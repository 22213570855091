// src/components/cookieConsent/CustomCookieConsent.js

import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { initializeGA, initializeGTM } from '../../utils/gatsby-gdpr';
import './cookieConsent.scss';

const CustomCookieConsent = ({ intl: { formatMessage } }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isPersonalizing, setIsPersonalizing] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: true,
    tagManager: true,
  });

  useEffect(() => {
    const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    if (!savedPreferences) {
      setIsVisible(true);
    } else {
      setPreferences(savedPreferences);
      if (savedPreferences.analytics) {
        initializeGA('G-HKW1C8WM9Y');
      }
      if (savedPreferences.tagManager) {
        initializeGTM('GT-55JKBM9Y');
      }
    }
  }, []);

  const handleAcceptAll = () => {
    const newPreferences = { necessary: true, analytics: true, tagManager: true };
    setPreferences(newPreferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(newPreferences));
    initializeGA('G-HKW1C8WM9Y');
    initializeGTM('GT-55JKBM9Y');
    setIsVisible(false);
  };

  const handleDeclineAll = () => {
    const newPreferences = { necessary: true, analytics: false, tagManager: false };
    setPreferences(newPreferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(newPreferences));
    setIsVisible(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    if (preferences.analytics) {
      initializeGA('G-HKW1C8WM9Y');
    }
    if (preferences.tagManager) {
      initializeGTM('GT-55JKBMZ');
    }
    setIsVisible(false);
  };

  const handlePersonalize = () => {
    setIsPersonalizing(true);
  };

  return isVisible ? (
    <div className="cookie_banner">
      <div className="container">
        <h3>{formatMessage({ id: 'cookieConsentTitle' })}</h3>
        <p>{formatMessage({ id: 'cookieConsentDescription' })}</p>
        <a href="/cookies" target="_blank" rel="noopener noreferrer">{formatMessage({ id: 'cookieConsentLearnMore' })}</a>
        {isPersonalizing ? (
          <div className="cookie-notice">
            <div className="cookie_list">
              <div className="cookie_item">
                <input type="checkbox" checked disabled />
                <label>{formatMessage({ id: 'cookieTypeEssential' })}</label>
                <p>{formatMessage({ id: 'cookieTypeEssentialDescription' })}</p>
              </div>
              <div className="cookie_item">
                <input
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={(e) => setPreferences({ ...preferences, analytics: e.target.checked })}
                />
                <label>{formatMessage({ id: 'cookieTypeAnalytics' })}</label>
                <p>{formatMessage({ id: 'cookieTypeAnalyticsDescription' })}</p>
              </div>
              <div className="cookie_item">
                <input
                  type="checkbox"
                  checked={preferences.tagManager}
                  onChange={(e) => setPreferences({ ...preferences, tagManager: e.target.checked })}
                />
                <label>{formatMessage({ id: 'cookieTypeTagManager' })}</label>
                <p>{formatMessage({ id: 'cookieTypeTagManagerDescription' })}</p>
              </div>
            </div>
            <div className="personalize_validation">
              <button className="save-button" onClick={handleSavePreferences}>
                {formatMessage({ id: 'cookieConsentSave' })}
              </button>
            </div>
          </div>
        ) : (
          <div className="button_wrapper">
            <button className="personalize-button" onClick={handlePersonalize}>
              {formatMessage({ id: 'cookieConsentPersonalize' })}
            </button>
            <button className="decline-button" onClick={handleDeclineAll}>
              {formatMessage({ id: 'cookieConsentDecline' })}
            </button>
            <button className="accept-button" onClick={handleAcceptAll}>
              {formatMessage({ id: 'cookieConsentAccept' })}
            </button>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default injectIntl(CustomCookieConsent);
