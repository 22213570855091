import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../navbar';
const Layout = ({ children }) => {
  return (
    <main>
      <Navbar />
      {children}
    </main>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node,
};
