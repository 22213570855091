import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import ContactInfo from '../contactInfo';
import SocialMedia from '../socialMedia';
import TermsAndConditions from '../termsAndConditionsFooter';
import * as styles from './Footer.module.scss';
const Footer = ({ intl: { formatMessage } }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__footer_container}>
        <div className={styles.footer__footer_container__content_text}>
          <StaticImage
            src="../../images/icon.webp"
            className={styles.footer__footer_container__img}
            alt="logo"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
          />
          <h5 className={styles.footer__footer_container__content_text__title}>
            {formatMessage({ id: 'weBringTheYears' })}
          </h5>
        </div>
        <div className={styles.footer__footer_container__content_text}>
          <h4>{formatMessage({ id: 'usefullLinks' })}</h4>
          <ul>
            <li>
              <Link to="/">{formatMessage({ id: 'home' })}</Link>
            </li>
            <li>
              <Link to="/aboutUs/">{formatMessage({ id: 'aboutUs' })}</Link>
            </li>
            <li>
              <Link to="/business/">{formatMessage({ id: 'businessTalent' })}</Link>
            </li>
            <li>
              <Link to="/professionals/">{formatMessage({ id: 'professionalsJob' })}</Link>
            </li>
            <li>
              <Link to="/contact/">{formatMessage({ id: 'contactUs' })}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footer__footer_container__content_text}>
          <ContactInfo />
        </div>
        <SocialMedia />
      </div>
      <hr className={styles.footer__footer_container__line} />
      <TermsAndConditions />
    </footer>
  );
};

export default injectIntl(Footer);

Footer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
