import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import * as styles from './TermsAndConditions.module.scss';

const TermsAndConditions = ({ intl: { formatMessage } }) => (
  <div className={styles.termsAndConditions}>
    <p>
      ©2024 Connecting Tech People.{' '}
      {formatMessage({ id: 'allRightsReserved' })}
    </p>
    <ul>
      <li>
        <Link to="/private-policy">
          {formatMessage({ id: 'privacyPolicy' })}
        </Link>
      </li>
      <li>
        <Link to="/cookies">Cookies</Link>
      </li>
    </ul>
  </div>
);

export default injectIntl(TermsAndConditions);
