import React from 'react';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { injectIntl } from 'react-intl';
import * as styles from './ContactInfo.module.scss';

const ContactInfo = ({ intl: { formatMessage } }) => (
  <div className={styles.contact_info}>
    <h4 className={styles.contact_info__title}>
      {formatMessage({ id: 'contactUs' })}
    </h4>
    <p className={styles.contact_info__item}>
      <FmdGoodOutlinedIcon fontSize="small" />
      <a href="https://maps.app.goo.gl/QtaQiXshoX9ASbFJ7" target="_blank" rel="noopener noreferrer">
        Paseo de la Castellana 91,4° - 1° 28046 Madrid
      </a>
    </p>
    <p className={styles.contact_info__item}>
      <PhoneOutlinedIcon fontSize="small" />
      <a href="tel:+34643916329">+34 643 91 63 29</a>
    </p>
    <p className={styles.contact_info__item}>
      <PhoneOutlinedIcon fontSize="small" />
      <a href="https://wa.me/34600207741">+34 600 20 77 41</a>
    </p>
    <p className={styles.contact_info__item}>
      <PhoneOutlinedIcon fontSize="small" />
      <a href="tel:+34911853110">+34 911 85 31 10</a>
    </p>
  </div>
);

export default injectIntl(ContactInfo);
