import React, { useState, Suspense, lazy, useRef } from 'react';
import { Formik } from 'formik';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import * as yup from 'yup';
import { Link } from 'gatsby';
import { parsePhoneNumber } from 'libphonenumber-js';
import { StaticImage } from 'gatsby-plugin-image';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Spinner } from '@components';
import * as styles from './Contact.module.scss';
import { useContact } from '../../hooks';

const Contact = ({ intl: { formatMessage } }) => {
  const reCaptchaElement = useRef(null);
  const formikRef = useRef(null);
  const Regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const { sendContact, isError, isSuccess, isLoading } = useContact({
    handleReset: () => {
      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    },
  });
  const initialValues = {
    email: '',
    name: '',
    message: '',
    phone: '',
    isChecked: false,
  };
  const [code, setCode] = useState('ES');
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const codePhones = {
    US: '+1', // United States
    ZA: '+27', // South Africa
    NL: '+31', // Netherlands
    FR: '+33', // France
    ES: '+34', // Spain
    IT: '+39', // Italy
    RO: '+40', // Romania
    GB: '+44', // United Kingdom
    DE: '+49', // Germany
    AR: '+54', // Argentina
    BR: '+55', // Brazil
    AU: '+61', // Australia
    JP: '+81', // Japan
    CN: '+86', // China
    IN: '+91', // India
    PT: '+351', // Portugal
    SA: '+966', // Saudi Arabia
    AE: '+971', // United Arab Emirates
  };

  const [isValidate, setIsValidate] = useState(false);
  const contactSchema = yup.object().shape({
    name: yup.string().required(formatMessage({ id: 'required' })),
    email: yup.string(),
    phone: yup.string(),
    message: yup.string().required(formatMessage({ id: 'required' })),
    code: yup.string(),
    isChecked: yup.bool().isTrue(formatMessage({ id: 'termsAccept' })),
  });

  const isValidForm = (values, setFieldError) => {
    if (!values.email && !values.phone && !values.message) {
      setFieldError('email', formatMessage({ id: 'invalidEmail' }));
      setFieldError('phone', formatMessage({ id: 'invalidPhone' }));
      setFieldError('message', formatMessage({ id: 'invalidMessage' }));
      return false;
    }

    if (values.email && !Regex.test(values.email)) {
      setFieldError('email', formatMessage({ id: 'invalidEmail' }));
      return false;
    }

    if (values.phone) {
      const phoneNumber = parsePhoneNumber(values.phone, code);
      if (!phoneNumber || !phoneNumber.isValid()) {
        setFieldError('phone', formatMessage({ id: 'invalidPhone' }));
        return false;
      }
    }

    if (values.message && !values.email && !values.phone) {
      setFieldError('email', formatMessage({ id: 'invalidEmail' }));
      setFieldError('phone', formatMessage({ id: 'invalidPhone' }));
      return false;
    }

    if (!values.message) {
      setFieldError('message', formatMessage({ id: 'invalidMessage' }));
      return false;
    }

    if (!recaptchaValue) {
      setFieldError('recaptcha', formatMessage({ id: 'invalidRecaptcha' }));
      return false;
    }

    return true;
  };

  return (
    <article className={styles.container_contact}>
      <div className={styles.container_contact__content_form}>
        <div className={styles.container_contact__content_titles}>
          <h3 className="title">{formatMessage({ id: 'connectWithUs' })}</h3>
          <h5 className="subtitle">
            {formatMessage({ id: 'ourYearsOfExperience' })}
          </h5>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={contactSchema}
          validateOnChange={isValidate}
          validateOnBlur={isValidate}
          onSubmit={async (values, actions) => {
            if (isValidForm(values, actions.setFieldError)) {
              await sendContact({
                email: values.email,
                name: values.name,
                message: values.message,
                code: codePhones[code],
                phone: values.phone,
              });
            }
          }}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
          }) => (
            <div className={styles.container_contact__container_form}>
              <div className={styles.container_contact__content_input}>
                <StaticImage
                  src="../../images/user.webp"
                  alt="user"
                  placeholder="blurred"
                  loading="lazy"
                  layout="constrained"
                  formats={['auto', 'webp', 'avif']}
                  objectFit="contain"
                  className={styles.container_contact__content_input__img}
                />
                <input
                  className={styles.container_contact__input}
                  name="name"
                  onChange={handleChange('name')}
                  onBlur={handleBlur('name')}
                  value={values.name}
                  placeholder={formatMessage({ id: 'fullName' })}
                />
              </div>
              {errors.name && touched.name ? (
                <small className="text_error">{errors.name}</small>
              ) : null}
              <div className={styles.container_contact__container_phone}>
                <select
                  className={
                    styles.container_contact__container_phone__select_phone
                  }
                  onChange={(v) => {
                    setCode(v.target.value);
                  }}
                  value={code}
                >
                  {Object.keys(codePhones).map((item) => (
                    <option value={item} key={item}>
                      {item} ({codePhones[item]})
                    </option>
                  ))}
                </select>
                <input
                  className={styles.container_contact__input}
                  name="phone"
                  placeholder={formatMessage({ id: 'phoneNumber' })}
                  type="tel"
                  onChange={handleChange('phone')}
                  onBlur={handleBlur('phone')}
                  value={values.phone}
                />
              </div>
              {errors.phone && touched.phone ? (
                <small className="text_error">{errors.phone}</small>
              ) : null}
              <div className={styles.container_contact__content_input}>
                <StaticImage
                  src="../../images/sms.webp"
                  alt="sms"
                  placeholder="blurred"
                  loading="lazy"
                  layout="constrained"
                  formats={['auto', 'webp', 'avif']}
                  objectFit="contain"
                  className={styles.container_contact__content_input__img}
                />
                <input
                  className={styles.container_contact__input}
                  name="email"
                  placeholder={formatMessage({ id: 'email' })}
                  type="email"
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                />
              </div>
              {errors.email && touched.email ? (
                <small className="text_error">{errors.email}</small>
              ) : null}
              <textarea
                name="message"
                placeholder={formatMessage({ id: 'message' })}
                rows={5}
                onChange={handleChange('message')}
                onBlur={handleBlur('message')}
                value={values.message}
                className={styles.container_contact__input}
              ></textarea>
              {errors.message && touched.message ? (
                <small className="text_error">{errors.message}</small>
              ) : null}
              <div className={styles.container_contact__content_terms}>
                <Checkbox
                  id="terms"
                  onChange={handleChange('isChecked')}
                  value={values.isChecked ? '1' : '0'}
                  sx={{
                    color: '#868d96',
                    '&.Mui-checked': {
                      color: '#2ed3ae',
                    },
                  }}
                />
                <label
                  className={styles.container_contact__text_term}
                  htmlFor="terms"
                >
                  {formatMessage({ id: 'iAgreeProvide' })}{' '}
                  <small className={styles.container_contact__text_privacy}>
                    <Link to="/private-policy">
                      {formatMessage({ id: 'privacyPolicy' })}
                    </Link>
                  </small>
                </label>
              </div>

              {errors.isChecked && touched.isChecked ? (
                <small className="text_error">{errors.isChecked}</small>
              ) : null}
              <ReCAPTCHA
                className={styles.container_contact__container_captcha}
                ref={reCaptchaElement}
                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                onChange={(value) => {
                  setRecaptchaValue(value);
                }}
              />
              {errors.recaptcha && (
                <small className="text_error">{errors.recaptcha}</small>
              )}
              {isError && (
                <p className={styles.container_contact__text_error}>
                  {formatMessage({ id: 'rejectContact' })}
                </p>
              )}
              {isSuccess && (
                <p className={styles.container_contact__text_success}>
                  {formatMessage({ id: 'responseContact' })}
                </p>
              )}
              <div className={styles.container_contact__content_btn}>
                <Button
                  type="submit"
                  style={isLoading ? styles.container_contact__loading : null}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {isLoading ? (
                    <Spinner isLoading={isLoading} />
                  ) : (
                    <p className="btn_text">
                      {formatMessage({ id: 'submit' })}
                    </p>
                  )}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <div className={styles.container_contact__content_images}>
        <StaticImage
          src="../../images/highlight_2.webp"
          alt="highlight_2"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
          className={styles.container_contact__highlight}
        />
        <StaticImage
          src="../../images/world.webp"
          alt="world"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          formats={['auto', 'webp', 'avif']}
          width={365}
          height={654}
        />
      </div>
    </article>
  );
};

export default injectIntl(Contact);

Contact.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};
